@import '../../../styles/base/palette.scss';
@import '../../../styles/base/breakpoints.scss';

.date-range-wrapper {
    display: flex;
    width: 20rem;

    .form-label {
        width: 100%;
    }

    .form-group {
        width: 7.5rem;

        @include for_breakpoint((sm, xs)){
            width: 8.5rem;
        }
    }

    .custom-date-picker {
        box-shadow: none;
        border-radius: 3px;
        border: 1px solid $mainBlue;
        background-color: $white;
        color: $mainBlue;
        font-size: 16px;
        height: 48px;
        font-family: 'AvenirNextCyr-Medium';
        width: 120px;
        padding: 0 0.5rem;

        &:focus {
            background-color: $catalinaBlue;
            color: $white;
            border-color: $scienceBlue;
            box-shadow: 0 0 0 0.2rem rgba(0, 103, 172, 0.5);
        }
        &:disabled {
            cursor: default;
            background-color: $concreteSolid !important;
            border-color: $silver;
        }
    }

    .date-range-arrow-divider {
        margin: auto 0.2rem 2rem 0.2rem;
        width: 1rem;

        @include for_breakpoint(xs) {
            margin: auto 0.1rem 2rem 0.2rem;
        }
    }
}