@import '../../../styles/base/breakpoints.scss';

.stock-chart-wrapper {
    @include for_breakpoint(md) {
        margin-left: -0.55rem;
        margin-top: 0 !important;
    }

    @include for_breakpoint((xs, sm)) {
        margin-top: 0 !important;
    }

    .highcharts-plot-border {
        stroke: #ebebeb;
        stroke-width: 1px;
    }

    .custom-crosshair {
        opacity: 0.5;
    }

    .highcharts-tick {
        display: none;
    }

    .highcharts-navigator-handle-right,
    .highcharts-navigator-handle-left {
        stroke: #093f85;
        stroke-width: 1px;
        fill: #FFF;
    }

    .highcharts-navigator-mask-outside {
        fill: rgba(0, 0, 0, 0.05);
    }

    .custom-tooltip-wrapper {
        font-size: 14px;
        width: 100%;

        .first-row {
            display: flex;
        }

        .second-row {
            margin-top: 4px;
            margin-left: 18px;
        }

        .index-value-label {
            font-family: AvenirNextCyr-Regular;
            font-weight: bold;
        }

        .index-value {
            font-family: AvenirNextCyr-Medium;
            float: right;
        }

        .index-date-value {
            font-family: AvenirNextCyr-Medium;
        }

        .index-id {
            font-family: AvenirNextCyr-Bold;
            font-weight: bold;
        }

        .index-id-dot {
            width: 8px;
            margin: 5px 4px;

            div {
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background-color: #65c8c6;
            }
        }
    }

    .custom-ticks {
        font-family: AvenirNextCyr-Medium;
        font-size: 14px;

        @include for_breakpoint(xs) {
            &.median {
                display: none;
            }
        }
    }
}