@import '../../../styles/base/palette.scss';
@import '../../../styles/base/breakpoints.scss';

.compound-annual-return-wrapper {
    width: 204px;
    height: 385px;
    border-top: 2px solid $catalinaBlue;
    border-bottom: 2px solid $catalinaBlue;
    background-color: $alabaster;

    @include for_breakpoint(xs) {
        width: 100%;
        height: 274px;
    }

    @include for_breakpoint(sm) {
        width: 100%;
        height: 195px;
        display: flex;
    }

    @include for_breakpoint(md) {
        width: 100%;
        height: 171px;
        display: flex;
    }

    .compound-annual-return-container {
        margin: 2rem auto;
        padding: 1.5rem 1rem;
        width: 156px;
        height: 135px;
        background-color: $casablanca;
        color: $white;

        @include for_breakpoint(xs) {
            width: 214px;
            text-align: center;
        }

        @include for_breakpoint(sm) {
            margin: 2rem 2rem;
        }

        @include for_breakpoint(md) {
            margin: 1rem 2rem;
        }

        p {
            margin: 0;
        }

        .annual-return-value {
            font-family: AvenirNextCyr-Regular;
            font-size: 29px;
            font-weight: bold;
            display: block;
            margin-bottom: 0.3rem;
        }

        .annual-return-text {
            font-family: AvenirNext-Regular;
            font-size: 16px;
            line-height: 18px;
            font-weight: bold;
        }
    }

    .compound-annual-return-body-text {
        font-size: 13px;
        font-weight: bold;
        font-family: AvenirNext-Regular;
        line-height: 17.5px;
        color: $tundora;
        width: 156px;
        margin: 2rem auto;

        @include for_breakpoint(xs) {
            width: 214px;

            br {
                display: none;
            }
        }

        @include for_breakpoint(sm) {
            margin: 3rem auto;
        }

        @include for_breakpoint(md) {
            margin: 3rem 2rem;
            width: 50%;
            height: 71px;
        }
    }
}