@import './datePickerOverrides.scss';
@import '../base/palette.scss';

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

body {
    min-width: 320px;
}

.bold {
    font-weight: bold !important;
    font-family: AvenirNextCyr-Bold;    
}

.form-label {
    font-size: 14px;
    color: $labelColor;
    font-weight: bold;
    font-family: AvenirNextCyr-Bold;
    padding-left: 1rem;
}

.custom-btn {
    background-color: $mainBlue !important;
    font-family: AvenirNextCyr-Regular;
    height: 32px;
    padding: 0.175rem 0.75rem !important;
}

.active {
    background-color: $dodgerBlue !important;
}

@mixin disable-selection {
    -webkit-touch-callout: none; 
    -webkit-user-select: none;   
    -khtml-user-select: none; 
    -moz-user-select: none;     
    -ms-user-select: none;     
    user-select: none;          
}

.no-selectable {
    @include disable-selection;
}