$white: #fff;
$mainBlue: #0067ac;
$black: #000;
$labelColor: #333333;
$textColor: #4e4e4e;
$borderColor: #979797;
$catalinaBlue: #093f85;
$alabaster: #f7f7f7;
$casablanca: #f5a641;
$tundora: #4d4d4d;
$scienceBlue: #0062cc;
$seashell: #f1f1f1;
$alto: #d8d8d8;
$mako: #3d4045;
$black: #000000;
$luckyPoint: #11175e;
$endeavour: #0067ac;
$shipCove: #6b8cb6;
$concreteSolid: #f2f2f2;
$silver: #c6c6c6;
$shakespeare: #56a0d3;
$nobel: #b3b3b3;
$dodgerBlue: #007bff;
$charcoal: #0e0e0e;
$lightCharcoal: #444;
