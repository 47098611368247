@import '../../styles/base/breakpoints.scss';
@import "../../styles/main.scss";

.not-found-container {
    padding: 10px 0;
    margin: 60px 0;
    margin-bottom: 150px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    min-height: 500px;

    .title {
        color: $mainBlue;
        font-family: AvenirNextCyr-Bold;
        font-size: 28px;
        text-align: center;

        @include for_breakpoint((xs, sm)) {
            font-size: 32px;
        }
    }
    .description-container {
        padding-top: 63px;
    }

    .description {
        color: $labelColor;
        font-family: AvenirNextCyr-Regular;
        font-size: 18px;
        line-height: 16px;
        text-align: center;
        padding-top: 18px;

        @include for_breakpoint((xs, sm)) {
            line-height: 22px;
            padding-top: 13px;
        }
    }

    .animation-container {
        width: 228px;
        height: 206px;
        margin-left: auto;
        margin-right: auto;
    }

    .btn-container {
        text-align: center;
        padding-top: 26px;
    }
    .btn {
        border-radius: 4px;
        width: 253px;
        height: 64px;
        background-color: $endeavour;
        font-size: 20px;
        padding-top: 15px;
        padding-bottom: 22px;
    }
}