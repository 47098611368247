@import '../../../styles/base/palette.scss';
@import '../../../styles/base/breakpoints.scss';

.custom-select-container {
    display: inline-block;
    min-width: 115px;
    width: 100%;
    position: relative;
    box-shadow: none;
    border-radius: 3px;
    border: 1px solid $mainBlue;
    background-color: $white;
    color: $mainBlue;
    font-size: 16px;
    height: 48px;
    font-family: 'AvenirNextCyr-Medium';
    background-image: url(./icon_arrowDown.svg);
    background-position: right 5% top 50%;
    background-repeat: no-repeat;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

    &.show {
        background-image: url(./icon_arrowDownSelected.svg) !important;
    }

    .selected-text {
        height: 100%;
        padding: 0.5rem 2rem 0.5rem 1rem;
        display: flex;
        align-items: center;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;

        &::after {
            content: "";
            position: absolute;
            right: 9px;
            top: 12px;
            border: 10px solid transparent;
            border-color: transparent transparent transparent transparent;
        }
        p {
          margin: 0px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
    }

    .selected-text.active {
        background-color: $catalinaBlue;
        color: $white;
        border-color: $scienceBlue;
        border-radius: 3px;
        box-shadow: 0 0 0 0.2rem rgba(0, 103, 172, 0.5);
    }

    ul {
        margin: 0;
        padding: 0;
    }

    .select-options {
        position: absolute;
        z-index: 1;
        top: 48px;
        background-color: $white;
        border: 1px solid $endeavour;
        border-radius: 3px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
        padding: 2px 0;
    }

    li {
        list-style-type: none;
        padding: 0.5rem 2rem 0.5rem 1rem;
        height: 48px;
        background: $white;
        font-size: 16px;
        cursor: pointer;
        color: $mainBlue;
        display: flex;
        align-items: center;
        white-space:nowrap;
        @include for_breakpoint((xs,sm)) {
          white-space: normal;
          height: initial;
        }
    }

    li:hover {
        background-color: $shipCove;
        border-radius: 3px;
        color: $white;
        margin: 0 2px;
    }
}