@import "../../styles/base/palette.scss";
@import "../../styles/base/breakpoints.scss";

.home-wrapper {
  padding: 3rem 0;

  .layout-chart-section {
    margin-top: 1rem !important;
  }

  .presets-label {
    width: 100%;

    @media (max-width: 768px) {
      margin: 0;
    }
  }

  .custom-btn {
    margin-right: 1rem;

    &:disabled{
        cursor: not-allowed;
    }

    @media (max-width: 768px) {
      margin-top: 1rem;
    }
  }
  

  .hypothetical-assumptions-section {
    margin-top: 1rem;

    .hypothetical-assumptions {
      font-size: 12px;
      color: $mako;
      font-family: AvenirNextCyr-Medium;
    }
  }

  .stock-chart-row {
    @include for_breakpoint((xs, sm)) {
      padding-left: 6px !important;
      padding-right: 6px !important;
    }
  }
}
