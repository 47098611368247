@import '../../../styles/base/palette.scss';

.footer {
    width: 100%;
    min-height: 700px;
    background-color: $mako;
    padding: 5.5rem 0 0 0;

    a {
        color: #f1f1f1;
        text-decoration-line: underline;
        &:hover {
          color: #f1f1f1;
        }
    }

    &-divider {
        height: 1px;
        background-color: $alto;
        opacity: 0.5;
        margin: 3rem 0 2rem 0;
    }

    p {
        font-family: AvenirNextCyr-Medium;
        font-size: 12px;
        color: $seashell;

        &:first-child {
            line-height: 20px;
            font-size: 16px;
        }

        &:last-child {
            padding-bottom: 2rem;
            margin-bottom: 0;
        }

        .text-right {
            float: right;
        }
    }

    .powered-by-annexus-logo {
        display: table;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 2rem;
    }
}