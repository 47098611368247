@import '../../../styles/base/breakpoints.scss';
@import '../../../styles/base/palette.scss';

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
  margin-right: .5rem;
  margin-left: .4rem;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.navbar {
  padding: 18px;
  background-color: rgba(252, 252, 252, 0.75) !important;
  transition: all 0.3s 0.5s ease-in-out;

  .toggler {
    @include for_breakpoint((xs, sm, md)) {
      margin-right: 15px;
      border: none;
    }
  }
  
  &.hide {
    top: -90px !important;
  }
  
  &.show {
    top: 0 !important;
  }

  .menu-btn {
    display: none;
  }

  .menu-icon {
    float: right;
    cursor: pointer;
    padding: 43px 30px 43px;
    position: relative;
    user-select: none;
    margin: 0;

    .navicon {
      background: $labelColor;
      display: block;
      height: 3px;
      border-radius: 3px;
      position: relative;
      transition: background 0.2s ease-out;
      width: 18px;

      &:before,
      &:after {
        background: $labelColor;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all 0.2s ease-out;
        width: 100%;
        border-radius: 3px;
      }

      &:before {
        top: 6px;
      }

      &:after {
        top: -6px;
      }
    }
  }

  .menu-btn {
    display: none;

    &:checked ~ ul.menu {
      max-height: 635px;
      -webkit-transition: max-height 0.2s ease-in;
      -moz-transition: max-height 0.2s ease-in;
      transition: max-height 0.2s ease-in;

      .collapse-arrow {
        display: block;
        position: absolute;
        right: 10px;
        cursor: pointer;
        margin: 14px;

        svg {
          vertical-align: top;
        }
      }
    }

    &:checked ~ .menu-icon {
      .navicon {
        background: transparent;

        &:before {
          transform: rotate(-45deg);
        }

        &:after {
          transform: rotate(45deg);
        }
      }

      &:not(.steps) {
        .navicon {
          &:before,
          &:after {
            top: 0;
          }
        }
      }
    }
  }

  @include for_breakpoint((lg, xl)) {
    &:not(.secondary-nav) {
      padding: 0;

      .container {
        transition: all 0.3s ease-in-out;

        @include for_breakpoint((xs, sm, md)) {
          max-width: 100%;
        }
        
        .logo-container {
          display: flex;
          align-items: center;

          @include for_breakpoint((xs, sm, md)) {
            margin-left: 15px;
            margin-top: 13px;
            margin-bottom: 13px;
          }
        }

        &.scrollUp {
          transition: all 0.3s 0.5s ease-in-out;
          height: 55px;
          svg {
            transition: all 0.3s 0.5s ease-in-out;
            width: 196.58px;
            height: 33px;
          }
        }

        &.scrollTop {
          height: 90px;
          svg {
            transition: all 0.3s 0.5s ease-in-out;
            width: 231px;
            height: 54px;
          }
        }

        .collapse,
        .nav-list {
          height: inherit;
          z-index: 10;
          position: relative;
        }

        .nav-list .nav-item {
          &:hover,
          &.selected {
            background-color: $shakespeare;
          }

          @include for_breakpoint((xs, sm, md)) {
            background-color: $white !important;
          }

          .nav-link {
            height: 100%;
            display: flex;
            align-items: center;
          }

          .dropdown-menu {
            background-color: $labelColor;
            margin-top: 0;
            margin-left: 15px;
            width: 280px;
            
            @include for_breakpoint((xs, sm, md)) {
              width: 100% ;
              margin-left: 0;
              background-color: $white;
              color: $lightCharcoal;
              border-radius: 0;
              padding-top: 0;
              padding-bottom: 0;
              display: block;
              border-bottom: none;
            }

            .dropdown-item {
              color: $nobel !important;
              white-space: normal;

              @include for_breakpoint((xs, sm, md)) {
                color: $lightCharcoal !important;
                font-family: OpenSans !important;
                font-size: 13px !important;
                padding-bottom: 10px !important;
                padding-top: 10px !important;
              }

              &:hover,
              &:focus {
                color: $white !important;
                background-color: transparent;

                @include for_breakpoint((xs, sm, md)) {
                  color: $lightCharcoal !important;
                  background-color: rgba(0, 0, 0, 0.03);
                }
              }
            }
          }
        }
      }
    }
  }

  @include for_breakpoint(md) {
    height: initial !important;
  }

  .dropdown-toggle::before {
    display: inline-block;
    margin-right: 10px;
    vertical-align: 0.195em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .dropdown-toggle::after {
    display: none;
  }

  &-nav {
    font-family: OpenSans-Bold;

    .nav-link {
      padding-top: 0.6rem !important;
      padding-bottom: 0.3rem !important;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      @include for_breakpoint((xs, sm, md)) {
        
        padding-left: 1.3rem !important;
        padding-right: 1.3rem !important;
      }
      
    }
  }

  .nav-item {
    @include for_breakpoint((xs, sm, md)) {
      border-bottom: 1px solid rgba(128,128,128,.2);

      a:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }

    .collapse-arrow {
      display: block;
      position: absolute;
      right: 10px;
      cursor: pointer;
      margin: 14px;

      svg {
        vertical-align: top;
        fill-opacity: .8;
        &:hover {
          fill-opacity: 1.5;
        }
      }
    }
    
    a {
      font-size: 14px;
      font-family: OpenSans-Bold;
      color: $charcoal !important;
      text-transform: uppercase;

      @include for_breakpoint((xs, sm, md)) {
        font-size: 13px !important;
        color: $lightCharcoal !important;
        padding-bottom: 14px !important;
        padding-top: 13px !important;
      }
    }

    .dropdown-item {
      font-size: 12px;
      text-transform: uppercase;
      color: $black !important;
      font-family: 'OpenSans';
      padding-top: 10px;
      padding-bottom: 10px;

      @include for_breakpoint((xs, sm, md)) {
        padding-left: 1.1rem;
        text-transform: none;
      }
    }
  }
}

.secondary-nav {
  background-color: $catalinaBlue !important;
  color: $white;
  font-size: 24px;
  height: 72px;
  font-family: 'AvenirNextCyr-Medium';
  margin-top: 90px;
  
  @include for_breakpoint((xs, sm, md)) {
    margin-top: 0;
  }

  .xs-visible {
    display: none;
  }

  .sm-visible {
    display: inline;
  }

  @include for_breakpoint(xs) {
    font-size: 24px;
    height: 109px;
    text-align: center;

    span {
      margin: auto;
    }

    .xs-visible {
      display: inline;
    }

    .sm-visible {
      display: none;
    }
  }

  @include for_breakpoint(xs);
}
