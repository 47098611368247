@import '../base/palette.scss';

.react-datepicker {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    border: 1px solid $endeavour;
    background-color: $white;
}

.react-datepicker__header {
    padding-top: 1rem;
    border-bottom: none;
    background-color: $white;
}

button.react-datepicker__navigation.react-datepicker__navigation--next {
    background: url('../../assets/icon_arrowRight.svg') no-repeat !important;
    width: 15px;
    height: 18px;
    border: none;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
}

button.react-datepicker__navigation.react-datepicker__navigation--previous {
    background: url('../../assets/icon_arrowLeft.svg') no-repeat !important;
    width: 15px;
    height: 18px;
    border: none;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
}

.react-datepicker__day {
    font-size: 14px;
    width: 37px;
    height: 31px;
    border-radius: 3px;
    color: $labelColor;
    font-family: AvenirNextCyr-Medium;
    margin: 0;
    line-height: 1.9rem;
}

.react-datepicker__day-names {
    font-size: 14px;
    color: $luckyPoint;
    font-family: AvenirNextCyr-Bold;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 1rem;
}

.react-datepicker__day-name {
    margin: 0.32rem;
}

.react-datepicker__current-month {
    color: $catalinaBlue;
    font-size: 16px;
    font-family: AvenirNextCyr-Medium;
    font-weight: normal;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
    background-color: $catalinaBlue !important;
    color: $white !important;
}

.react-datepicker__month {
    margin: 0.4rem 1.5rem 1rem 1.5rem;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
    background-color: $catalinaBlue !important;
    color: $white;
    border-radius: 3px !important;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range,
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-rang {
    border-radius: 0px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
    border-radius: 0px;
    color: $labelColor;
    background-color: rgba(9, 63, 133, 0.1);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range,
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range {
    background-color: rgba(9, 63, 133, 0.1);
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc !important;
  background-color: #fff !important;
  &:hover {
    background-color: transparent !important;
  }
}
